import { type PlanCode, PricingProductEnum } from "@finq/pricing/types"

const productId = PricingProductEnum.Pensions

export const usePensionSubscribe = () => {
  const { scrollToPayment } = useScrollToPayment()

  const {
    isSubscribed,
    isSubscribedFn,
    handleVisitorAction: _handleVisitorAction,
  } = useIsSubscribed({ productIds: productId })

  const { isMobile } = useDisplay()

  function handleVisitorAction(params?: { planCodes?: PlanCode[]; callback?: VoidFunction }) {
    _handleVisitorAction({
      callback: () => {
        scrollToPayment({
          productId: productId,
          elementId: "#pension-subscribe-section",
          offsetY: isMobile.value ? 184 : 20,
        })

        params?.callback?.()
      },
      planCodes: params?.planCodes,
    })
  }

  return { handleVisitorAction, isSubscribed, isSubscribedFn }
}
